import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from 'react-router-dom'

import Header from "App/components/Layout/Header";
import { CustomContext } from "App/helper/CustomContext";
import { getStorage } from "App/helper/storage";
import Footer from "App/components/Layout/Footer";
import { SportTabs, Sidebar, BottomNavBar, LoginComp, Register } from "App/components";
import { Images } from "App/constants";
import { useScreenDetector } from "App/hooks/useScreenDetector";
import { useSelector } from "react-redux";
import { LoginModal } from "App/modals";
import RegisterModal from "App/modals/RegisterModal";
import images from "App/constants/images";
import { Carousel } from 'react-responsive-carousel';
import NavBar from "App/components/Layout/NavBar";

const UserLayout = () => {
    const { authModal } = useSelector(state => state.auth)
    const { GlobalAnnouncement, bannerList } = useSelector(state => state.match)

    const { isMobile } = useScreenDetector();
    const { pathname } = useLocation();
    const [isMode, setIsMode] = useState(false);
    useEffect(() => {
        let mode = localStorage.getItem('theme')
        document.body.classList.add(mode);
        setIsMode(true)
    });

    const [topbarIsOpen, setTopbarOpen] = useState(false);
    const [showKycModal, setShowKycModal] = useState(false);
    const [profileData, setProfileData] = useState(
        getStorage("logged-in")?.result
    );

    return (
        <CustomContext.ModalContext.Provider value={{ showKycModal, setShowKycModal }} >
            <CustomContext.ProfileData.Provider value={{ profileData, setProfileData }} >
                <div className={isMobile ? "h-[calc(100%_-_16rem)]" : ""}>
                    <Header toggleTopbar={() => setTopbarOpen(!topbarIsOpen)} topbarIsOpen={topbarIsOpen} />
                    {/* <NavBar /> */}
                    <div className="mainContent_bx">

                        {/* <div className="flex">
                            {(authModal == "LOGIN_COMP" || authModal == "LOGIN" || authModal == null) ? <LoginComp />
                                : authModal == "REGISTER_COMP" ? <Register /> : null}

                            {bannerList && bannerList.list && bannerList.list.length > 0 &&
                                <Carousel autoPlay showThumbs={false} infiniteLoop className='md:rounded overflow-hidden mt-0 mb-2 md:my-2 anvCarousal_control'>
                                    {bannerList.list.map((item, key) => (
                                        <div className='crs-imgOtr'><img src={isMobile ? item.mobile_banner : item.desktop_banner} /></div>
                                    ))}
                                </Carousel>
                            }
                        </div> */}

                        <div className="bg-[#110202] ">
                            <div className="flex">
                                <div className="outerSide_bar "><Sidebar /></div>
                                <div className="w-full lg:w-[calc(100%-228px)]">
                                    {/* <div className="w-full"> */}
                                    <div className="rightNside">
                                        <Outlet />
                                    </div>
                                    {
                                        (
                                            // !isMobile && 
                                            (
                                                pathname != '/inplay' && pathname != '/favorite' &&
                                                pathname != '/casino' && pathname != '/casino-market' &&
                                                pathname != '/login' && pathname != '/in-play' &&
                                                pathname != '/all-sports')) &&
                                        <Footer />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile && <BottomNavBar />}
                {authModal == "LOGIN" ? <LoginModal />
                    : authModal == "REGISTER" ? <RegisterModal /> : null}

                {/* {GlobalAnnouncement && GlobalAnnouncement.whatsapp_number && <div className="fixed z-50 right-2 bottom-[70px] md:bottom-3 animate-bounce">
                    <a href={`https://wa.me/${GlobalAnnouncement.whatsapp_number}?text=hii`} target="_blank">
                        <img src={images.WHATSAPP_ICON} className="h-10 w-10 md:h-16 md:w-16" />
                    </a>
                </div>} */}
            </CustomContext.ProfileData.Provider>
        </CustomContext.ModalContext.Provider>
    )
}

export default UserLayout