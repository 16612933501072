import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TermsAndConditions = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">Terms and Conditions</h1>

            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">1. GENERAL TERMS AND DEFINITIONS</h2>
                <p className="mb-4">
                    The following Betting Rules pertaining to the Bookmaker Future9Club (hereinafter referred to as the Rules) stipulate the manner of accepting bets, paying winnings and resolving disputes, as well as the specific features of certain bets on certain sports. These Rules shall govern any other relations between the Future9Club and the customer.
                </p>
                <p className="mb-4">
                    These Rules shall apply to betting on the website and at Future9Club betting facilities.
                </p>

                <ul className="list-disc pl-5">
                    <li className="mb-2">
                        <strong>Bet</strong> - is a risk-driven agreement for potential winnings entered into between the customer and the bookmaker under the established Rules, where the fulfillment of such agreement is conditioned by an event whose outcome is yet to be determined. Bets are accepted on the conditions offered by the bookmaker.
                    </li>
                    <li className="mb-2">
                        <strong>Outcome</strong> - is the result of the event (events) on which the bet was placed.
                    </li>
                    <li className="mb-2">
                        <strong>Customer</strong> - is an individual placing a bet with the bookmaker on an outcome.
                    </li>
                    <li className="mb-2">
                        <strong>Bet Cancellation</strong> - is an outcome on which the bet is not settled and winnings are not paid. As per the Rules, in the event of “bet cancellation”, an arrangement between the bookmaker and the customer shall be deemed unconcluded and the stake shall be refunded.
                    </li>
                    <li className="mb-2">
                        <strong>Regular Time</strong> - is the duration of the match subject to the regulations of the relevant sport, including time added by the referee. Regular time does not include extra time, overtime(s), penalty shootouts, etc.
                    </li>
                </ul>
            </section>
        </div>
    );
};

export default TermsAndConditions;
