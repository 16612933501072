import React from "react";
import { Images } from "App/constants";
import { Link } from "react-router-dom";
import { getStorage } from "App/utils";
import { useSelector } from "react-redux";

export default function Footer() {
  const { currentUser } = useSelector((state) => state.auth);

  const logos = [
    { src: Images.EGUZI_LOGO, alt: 'Eguzi Logo' },
    { src: Images.AG_LOGO, alt: 'AG Logo' },
    { src: Images.BET_GAMES_LOGO, alt: 'Bet Games Logo' },
    { src: Images.EVO_LOGO, alt: 'Evolution Games Logo' },
    // { src: Images.NETENT_LOGO, alt: 'NetEnt Logo' },
    // { src: Images.TVBET_LOGO, alt: 'TVBet Logo' },
    { src: Images.SUPER_SPADE_LOGO, alt: 'Super Spade Logo' },
    { src: Images.SUPERNOVA_LOGO, alt: 'Supernova Logo' },
    // { src: Images.BTI_LOGO, alt: 'BTI Logo' },
    // { src: Images.MOJ_LOGO, alt: '7Mojos Logo' },
    { src: Images.SPRIBE_LOGO, alt: 'Spribe Logo' },
  ];
  const payment_methods = [
    { src: Images.CRYPTO_LOGO, alt: 'net bank Logo' },
    { src: Images.NET_BANK_LOGO, alt: 'net bank Logo' },
    { src: Images.UPI_LOGO, alt: 'upi Logo' },
    { src: Images.GPAY_LOGO, alt: 'gpay  Logo' },
    { src: Images.PAYTM_LOGO, alt: 'paytm Logo' },
    { src: Images.PHONEPE_LOGO, alt: 'PHONEPE_LOGO Logo' },
  ];
  const social = [
    { src: Images.TELEGRAM.default, alt: 'telegram Logo' },
    { src: Images.WHATSAPP.default, alt: 'whatsapp Logo' },
  ];

  return (
    <footer className=" footerMain_bx">

      <div className="text-center">
        <small>Copyright ©️ 2024 Future9Club. All rights reserved.</small>
      </div>

    </footer >
  );
}
