import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import _ from "App/helper/_";
import { getSelectedSportTabRequest, toggleAuthModalRequest } from 'App/_redux/_actions';
import { FaHome, FaPlay, FaDice, FaUser, FaUserCircle } from 'react-icons/fa';
import { Images } from 'App/constants';
import { GiCardExchange, GiPoolTriangle } from 'react-icons/gi';
import { FaClock } from 'react-icons/fa6';
import { MdAccountBalance, MdSupportAgent } from 'react-icons/md';

const BottomNavBar = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);
    const { selectedSportTab } = useSelector((state) => state.match);

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type));
    };


    const redirectTo = () => {
        // if (!(!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username))
        //     dispatch(getSelectedSportTabRequest({ title: "In Play", count: "6", icon: <FaClock size={20} />, id: 2, link: '/inplay', type: 1 }))
    }

    return (
        <div className="btm-nav md:hidden bottomNav">
            {/* <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login'} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <FaHome size={20} className={pathname === (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login') ? "text-active" : "intext-active"} />
                <span className="btm-nav-label">Home</span>
            </NavLink> */}

            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino' : '/casino-market'} className={({ isActive }) => `${isActive ? "active" : ""} `}>
                <GiPoolTriangle size={20} className={pathname === (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino' : '/casino-market') ? "text-secondary-foreground" : "intext-active"} />
                <span className="btm-nav-label">Casino</span>
            </NavLink>

            <NavLink onClick={() => { redirectTo() }} to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/inplay' : '/in-play'} className={({ isActive }) => `${isActive ? "active" : ""} ${selectedSportTab.link === '/inplay' ? "active" : ""}`}>
                <FaPlay size={20} className={pathname === (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/inplay' : '/in-play') ? "text-secondary-foreground" : "intext-active"} />
                <span className="btm-nav-label">In Play</span>
            </NavLink>
            {/* <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino' : '/casino-market'} className={({ isActive }) => `${isActive ? "casinoActive" : ""}  mt-[-30px]`}>
                <div className='casino'>
                    <img src={Images.BN_CASINO.default} alt="" />
                </div>
                <span className="btm-nav-label">Casino</span>
            </NavLink> */}
            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login'} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <GiCardExchange size={20} className={pathname === (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login') ? "text-secondary-foreground" : "intext-active"} />
                <span className="btm-nav-label">All</span>
            </NavLink>
            <NavLink to={"/bet-history"} onClick={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? () => { } : () => toggleAuthModal("LOGIN")} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <FaUser size={20} className={pathname === '/bet-history' ? "text-secondary-foreground" : "intext-active"} />
                <span className="btm-nav-label">My Bets</span>
            </NavLink>
            {/* <NavLink to={"/profile"} onClick={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? () => { } : () => toggleAuthModal("LOGIN")} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <FaUserCircle size={20} className={pathname === '/profile' ? "text-active" : "intext-active"} />
                <span className="btm-nav-label">Profile</span>
            </NavLink> */}
            {/* <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/support' : '/support-d'} className={({ isActive }) => `${isActive ? "active" : ""} `}>
                <MdSupportAgent size={20} className={pathname === (!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/support' : '/support-d') ? "text-active" : "intext-active"} />
                <span className="btm-nav-label">Support</span>
            </NavLink> */}

            <NavLink to={"/statement"} onClick={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? () => { } : () => toggleAuthModal("LOGIN")} className={({ isActive }) => `${isActive ? "active" : ""}`}>
                <MdAccountBalance size={20} className={pathname === '/statement' ? "text-secondary-foreground" : "intext-active"} />
                <span className="btm-nav-label">Accounts</span>
            </NavLink>

        </div>
    );
};

export default BottomNavBar;
