import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { casinoLogin, diamondCasinoLogin, getUserbalance, qtechCasinoList, qtechCasinoLogin } from "App/_redux/_services";
import { Carousel } from 'react-responsive-carousel';
import { Images } from 'App/constants';
import _ from "App/helper/_";
import { LoginModal } from "App/modals";
import { useDispatch, useSelector } from 'react-redux';
import { CasinoMarket, CasinoSidebar } from 'App/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCasinoBannersRequest, toggleAuthModalRequest, updateUserBalance } from 'App/_redux/_actions';
import toast from 'react-hot-toast';
import { useScreenDetector } from 'App/hooks/useScreenDetector';
import Footer from 'App/components/Layout/Footer';
import { BsChevronDown } from 'react-icons/bs';

import io from "socket.io-client";
import config from "App/lib/app-config";
import SocketEvent from 'App/context/SocketEvent';
import { DotLoader } from 'react-spinners';

const Casino = () => {

    const { isMobile } = useScreenDetector();
    const { currentUser } = useSelector((state) => state.auth);
    const { masterData, GlobalAnnouncement, casinoBannerList } = useSelector((state) => state.match);
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [casinoFilter, setCainoFilter] = useState({ type: 'ALL' })
    const [showModal, setShowModal] = useState(false);
    const [casinoUrl, setCasinoUrl] = useState(null);
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(false);

    const [state, setStateValue] = useState({
        qTechList: [],
        qTechProviders: []
    })

    const [selectedCategory, setSelectedCategory] = useState('');

    const setState = (obj, callback) => {
        setStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    // useEffect(() => {
    //     fetchQtechCasino()
    // }, [])

    // const fetchQtechCasino = async () => {
    //     let param = {}
    //     try {
    //         const res = await qtechCasinoList(param);
    //         if (res.data.game_list) {
    //             setState({ qTechList: res.data.game_list, qTechProviders: res.data.providers })
    //         }
    //     } catch (error) { }
    // }


    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    useEffect(() => {

        if (params.provider_code == "qtech") {
            if (params && params.game_code) {
                let game_id = (params.game_code).replace(/&&/g, '/')

                fetchQtechCasinoUrl(game_id == "undefined" ? '' : game_id)

            }

            return
        }

        if (params.provider_code && params.provider_code == "indian_casino") {
            // const item = location.state
            if (params && params.game_code) {
                let gmCode = (params.game_code).replace(/&&/g, '/')
                fetchDiamondCasino(gmCode)

            }
            return
        }


        if (params.provider_code && params.provider_code != "") {
            fetchCasino(params.provider_code, params.game_code)
        }
    }, [params, params.provider_code, params.game_code])

    useEffect(() => {

        if (casinoBannerList == null) {
            let param = {
                type: "Casino"
            }
            dispatch(getCasinoBannersRequest(param, (response) => {
                if (response && response.data) {

                }
            }))
        }
    }, []);

    const fetchDiamondCasino = async (game_code) => {
        let param = { game_code }
        try {
            const res = await diamondCasinoLogin(param);
            if (res.data.launchURL == null) {
                setLoading(false)
                toast.error('Launching next week.')
                navigate(-1)
            }
            if (res.data.launchURL) {
                setLoading(false)
                setCasinoUrl(res.data.launchURL)
            }

        } catch (error) {
            if (error && error.error != null) {
                toast.error(error.error)
            }
            navigate(-1)
            setLoading(false)
        }
    }



    const fetchCasino = async (provider_code, game_code) => {
        setLoading(true)
        let param = { provider_code }
        if (params.game_code && params.game_code != "") {
            param['gameCode'] = game_code
        }

        try {
            const res = await casinoLogin(param);
            if (res.launchURL == null) {
                setLoading(false)
                toast.error('Launching next week.')
                navigate(-1)
            }
            if (res.launchURL) {
                setCasinoUrl(res.launchURL)
                setLoading(false)
            }
        } catch (error) {
            navigate(-1)
            setLoading(false)
        }
    }

    const dummyFun = () => { }


    const createConnection = useCallback((socket) => {
        socket.on("connect", () => {
            setConnected(socket.connected);
        });
        socket.on(SocketEvent.UPDATE_BALANCE, handleConnected);
    }, []);

    useEffect(() => {
        let socket = io(config.socketurl, {
            reconnectionDelayMax: 100000,
            extraHeaders: {},
        });
        createConnection(socket);
        return () => {
            closeRoom(socket, "payin_callback");
            socket.off(SocketEvent.UPDATE_BALANCE, handleConnected);
            socket.off();
            socket.disconnect();
        };
    }, [createConnection]);

    const closeRoom = (socket, pay) => {
        socket.emit("unsubscribe", pay);
    };

    const handleConnected = (payload) => {
        if (payload) {
            getUserbalance()
                .then((res) => {
                    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                    currentUser.balance = res.data.balance;
                    currentUser.exposure = res.data.exposure;
                    currentUser.bonus_balance = res.data.bonus_balance;
                    currentUser.withdrawal_balance = res.data.withdrawal_balance;
                    localStorage.setItem("currentUser", JSON.stringify(currentUser));
                    dispatch(updateUserBalance(res.data))
                })
                .catch((error) => {
                    //  callback()
                });
        }
    };

    const fetchQtechCasinoUrl = async (game_code) => {
        let param = { game_code }
        setLoading(true)
        try {
            const res = await qtechCasinoLogin(param);
            if (res.data.url) {
                setCasinoUrl(res.data.url)
            }
        } catch (error) {
            if (error && error.error != null) {
                toast.error(error.error)
            }
            navigate(-1)
        } finally {
            setLoading(false);
        }
    }


    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    // const selectedGames = state.qTechList.find(category => category.id === selectedCategory)?.games || [];
    const selectedCategoryGames = state.qTechList.find(category => category.id === selectedCategory)?.games;
    const selectedGames = selectedCategoryGames ? selectedCategoryGames : state.qTechList.flatMap(category => category.games);


    return (
        <Fragment>
            {loading ?
                <div className="midLayout h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar pb-2">
                    <div className="flex justify-center items-start text-center py-12  h-screen">
                        <div className="px-24 py-8 bg-secondary text-secondary-foreground flex justify-center items-center">
                            <DotLoader color="#0b81db" />
                        </div>
                    </div>
                </div>
                :
                <Fragment>
                    {casinoUrl && casinoUrl != null ?
                        <div className='w-full h-screen fixed top-0 left-0 z-[200]'>

                            <Link to="/casino" className='absolute left-[0px] z-[201] bg-[#2b2b2b] text-gray-50 px-[0.9rem] py-1 cursor-pointer text-center font-bold'>
                                <p>Home</p>
                            </Link>

                            <iframe className='w-full h-screen' src={casinoUrl} frameborder="0" ></iframe>
                        </div>
                        :
                        <Fragment>
                            <div className="midLayout h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar pb-2">
                                {/* <div className="flex anvMarquee  h-[28px] md:h-7 items-center md:my-1 md:rounded overflow-hidden">
                                    <img className="h-4 px-1" src={Images.INFO.default} />
                                    <marquee className="text-[12px] flex items-center font-semibold " behavior="" direction="" >
                                        {GlobalAnnouncement != null ? GlobalAnnouncement.client_msg : `“Welcome to Velocity999 !!”`}
                                    </marquee>
                                </div> */}

                                <div className='mb-3 sm:!mb-4'>
                                    {casinoBannerList && casinoBannerList.list && casinoBannerList.list.length > 0 &&
                                        <Carousel autoPlay showThumbs={false} infiniteLoop className='md:rounded overflow-hidden mt-0 mb-2 md:my-2 anvCarousal_control'>
                                            {casinoBannerList.list.map((item, key) => (
                                                <div className='crs-imgOtr'><img src={isMobile ? item.mobile_banner : item.desktop_banner} /></div>
                                            ))}
                                        </Carousel>
                                    }
                                </div>

                                {state.qTechProviders && state.qTechProviders.length > 0 &&
                                    <div className='bg-secondary text-secondary-foreground rounded-[8px]'>
                                        <h4 className="p-3 text-[16px] font-semibold basis-full mb-[8px] sm:mb-4">
                                            Providers
                                        </h4>

                                        {/* <div className='flex overflow-auto no-scrollbar w-full'>
                                            {state.qTechProviders.map((item, ket) => {
                                                return (
                                                    <div className='slideCasinos '>
                                                        <Link to={`/casino/qtech/${item.id ? item.id : undefined}`} key={item?.id}
                                                            className="cursor-pointer "
                                                            onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? dummyFun() : toggleAuthModal("LOGIN") }}>
                                                            <img src={item.image} alt="" className="w-full h-full rounded" />
                                                        </Link>
                                                    </div>
                                                )
                                            })}
                                        </div> */}

                                        <div className="flex flex-wrap gap-[12px] p-3">
                                            {state.qTechProviders.map((item, index) => (
                                                <Link to={`/casino/qtech/${item.id ? item.id : undefined}`} key={item?.id} className="inrCasino-items ">
                                                    <img src={item.image} alt={item.name} className="thumbImg h-32" />
                                                </Link>
                                            ))}
                                        </div>


                                    </div>}

                                {/* {isMobile && masterData && masterData.poular_casinos && masterData.poular_casinos.length > 0 &&
                                    <div className=' bg-secondary text-secondary-foreground rounded-[8px] mt-3'>
                                        <h4 className="p-3 text-[16px] font-semibold basis-full mb-[8px] sm:mb-4">
                                            Popular Games
                                        </h4>

                                        <div className='flex overflow-auto no-scrollbar w-full'>
                                            {masterData.poular_casinos.map((item, ket) => {
                                                return (
                                                    <div className='slideCasinos '>
                                                        <Link to={`/casino/${item.providerCode}/${item.code}`} className="cursor-pointer " onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? dummyFun() : toggleAuthModal("LOGIN") }}>
                                                            <img src={item.thumb} alt="" className="w-full h-full rounded" />
                                                        </Link>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>} */}

                                {masterData && masterData.diamond_casino && masterData.diamond_casino.length > 0 &&
                                    <div className='bg-secondary text-secondary-foreground rounded-[8px]'>
                                        <h4 className="p-3 text-[16px]  font-semibold basis-full mb-[8px] sm:mb-4">
                                            Indian Casino
                                        </h4>

                                        <div className="flex flex-wrap gap-[12px] p-3">
                                            {masterData.diamond_casino.map((item, idx) => {
                                                return (
                                                    // <div className='slideCasinos '>
                                                    //     <Link to={`/casino/indian_casino/${(item.game_code).replace(/\//g, '&&')}`} className="cursor-pointer " onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? dummyFun() : toggleAuthModal("LOGIN") }}>
                                                    //         <img src={item.image} alt="" className="w-full h-full rounded" />
                                                    //     </Link>
                                                    // </div>
                                                    <Link to={`/casino/indian_casino/${(item.game_code).replace(/\//g, '&&')}`} key={idx} className="inrCasino-items ">
                                                        <img src={item.image} alt="" className="thumbImg " />
                                                        <div className=' '>

                                                        </div>
                                                    </Link>
                                                )
                                            })}

                                        </div>
                                    </div>}



                                {/* {masterData && masterData.all_casinos && masterData.all_casinos.length > 0 &&
                                    <div className='p-3 bg-secondary text-secondary-foreground rounded-[8px] mt-3 w-full m-auto mb-3'>
                                        <div className='md:flex text-[16px] font-semibold  mb-4 gap-2 items-center justify-between'>
                                            <h4 className='mb-2 md:mb-0'> Casino</h4>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <div className='md:w-[200px] relative'>
                                                 

                                                </div>

                                                {state.qTechList && state.qTechList.length > 0 &&
                                                    <div className='md:w-[200px] relative'>
                                                        <select
                                                            className="cstFrm_inputs form-control cursor-pointer" name="sports_id"
                                                            value={casinoFilter.type}
                                                            onChange={(e) => { setCainoFilter({ type: e.target.value }); handleCategoryClick(e.target.value) }}
                                                        >
                                                            <option value="ALL">All Games</option>
                                                            {state.qTechList.map((category, key) => (
                                                                <option key={key} value={category.id}>
                                                                    {category.title}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <BsChevronDown className="anvChevron" />
                                                    </div>}
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap gap-[12px] ">

                                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4">
                                                {selectedGames.map((game, index) => (
                                                    <Link to={`/casino/qtech/${game.id}`} key={index} className="inrCasino-items ">
                                                        <img src={game.image} alt={game.name} className="thumbImg " />
                                                        <div className=' '>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>


                                
                                        </div>
                                        
                                    </div>
                                } */}


                                <Footer />
                            </div>
                            <div className="w-[368px] xl:block hidden h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar">
                                <CasinoSidebar />
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    )
}

export default Casino