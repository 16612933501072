import React from 'react'

const AnimatedButton = ({ children }) => {
    return (
        <div className="inPlay-blinking-dot">
            <div className="mac-inplay-icon-wrapper">
                {children}
            </div>
        </div>

        // <div className="inPlay-blinking-dot before:bg-header_gradient 
        // dark:before:bg-animated_dark_border !p-0 flex items-center text-base w-max gap-2  justify-center 
        // whitespace-nowrap flex-1  md:min-w-[130px] md:px-3 px-5 min-w-[85px] text-white 
        // bg-[var(--game-selected-background)]  cursor-pointer text-[13px] 
        // font-semibold leading-[17px] tracking-normal text-left h-[35px]">
        //     <div className="mac-inplay-icon-wrapper flex items-center text-base 
        //     gap-2  justify-center whitespace-nowrap flex-1 w-full 
        //     cursor-pointer text-[13px] font-semibold leading-[17px] tracking-normal text-left 
        //       ">
        //         {title}
        //     </div>
        // </div>
    )
}

export default AnimatedButton