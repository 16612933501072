import React, { Fragment, useEffect, useState } from 'react'
import { getAllSportRequest, getSelectedSportRequest, getSelectedSportTabRequest, toggleAuthModalRequest } from "App/_redux/_actions";
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Images } from 'App/constants';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import _ from "App/helper/_";
import { useScreenDetector } from 'App/hooks/useScreenDetector';
import { constant } from 'App/helper/constant';
import { qtechCasinoList } from 'App/_redux/_services';
import AnimatedButton from '../AnimatedButton';

const SportTabs = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isMobile } = useScreenDetector();
    let dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const { selectedSportId, selectedSportTab, masterData, bannerList, GlobalAnnouncement } = useSelector((state) => state.match);
    const [allSports, setAllSports] = useState(null);


    const [state, setStateValue] = useState({
        qTechList: [],
        qTechProviders: []
    })

    const [selectedCategory, setSelectedCategory] = useState('');

    const setState = (obj, callback) => {
        setStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    useEffect(() => {
        fetchQtechCasino()
    }, [])

    const fetchQtechCasino = async () => {
        let param = {}
        try {
            const res = await qtechCasinoList(param);
            if (res.data.game_list) {
                setState({ qTechList: res.data.game_list, qTechProviders: res.data.providers })
            }
        } catch (error) { }
    }

    const getSport = (data, type) => {
        const param = { type: type == "1" ? 1 : type == "2" ? 2 : 1 }
        if (currentUser.user_id) {
            param['user_id'] = currentUser.user_id
        }
        dispatch(getSelectedSportRequest(data))
    }

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    const dummyFun = () => { }

    const redirectTo = (item, type) => {
        dispatch(getSelectedSportTabRequest(item))
        dispatch(getSelectedSportRequest({ sports_id: "4", sports_name: 'Cricket' }))
        const param = {
            type,
        }

        dispatch(getAllSportRequest((param), (res) => { if (res) setAllSports(res) }));
        navigate('/login')
    }




    return (
        <React.Fragment>
            {(pathname.slice(0, pathname.lastIndexOf('/')) != "/detail" && pathname != "/favorite" && pathname != "/inplay") ?
                <Fragment>
                    {bannerList && bannerList.list && bannerList.list.length > 0 &&
                        <Carousel autoPlay showThumbs={false} infiniteLoop className='md:rounded overflow-hidden mt-0 mb-2 md:my-2 anvCarousal_control'>
                            {bannerList.list.map((item, key) => (
                                <div className='crs-imgOtr'><img src={isMobile ? item.mobile_banner : item.desktop_banner} /></div>
                            ))}
                        </Carousel>
                    }

                    {/* {state.qTechProviders &&
                        <div className='flex overflow-auto no-scrollbar w-full'>
                            {state.qTechProviders.map((item, key) => {
                                return (
                                    <div className='Casinos-slide ' key={key}>
                                        <Link to={`/casino/qtech/${item.id ? item.id : undefined}`} key={item?.id}
                                            className="cursor-pointer " onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? dummyFun() : toggleAuthModal("LOGIN") }}>
                                            <img src={item.image} alt="" className="w-full h-full rounded md:!h-28" />
                                        </Link>
                                    </div>
                                )
                            })}

                        </div>} */}
                </Fragment>
                :
                (pathname == "/favorite" || pathname == "/inplay") ?
                    <div className='flex text-[16px] font-[500] leading-[19.36px] ml-2.5 md:ml-0 my-4 text-primary-foreground'>
                        {pathname == "/favorite" && <Fragment><img src={Images.STAR.default} alt="" className='h-5 w-5 mr-2' /><span>Favorite</span></Fragment>}
                        {pathname == "/inplay" && <Fragment><img src={Images.PLAY.default} alt="" className='h-5 w-5 mr-2' /><span>In-Play</span></Fragment>}
                    </div>
                    : null
            }


            <div className=" overflow-auto">
                <div className="w-auto w- [720px] sm:w-full max-w-[1377px] mx-auto  h-[42px] md:h-[52px]  whitespace-nowrap">
                    <ul className="h-full flex">
                        {constant.marketType.map((item, key) => (
                            <li className="h-full" key={item?.title} >
                                {currentUser.username ?
                                    <NavLink to={item.link} onClick={() => getSport({ sports_id: "4", sports_name: 'Cricket' })} className={({ isActive }) => `anvMenu_btns text-xs md:text-base justify-center w-full h-full !px-[20px] md:!px-[22px] flex items-center gap-2 ${isActive ? "active" : ""}`}>
                                        {item.icon}
                                        <span className=" block">
                                            {item?.title}{" "}
                                        </span>
                                    </NavLink>
                                    :
                                    null
                                }
                            </li>
                        ))}
                        {constant.marketTypeLogin.map((item, key) => (
                            <li className="h-full" key={item?.title} >
                                {!currentUser.username ?
                                    <NavLink to={item.link} onClick={() => getSport({ sports_id: "4", sports_name: 'Cricket' })} className={({ isActive }) => `anvMenu_btns text-xs md:text-base justify-center w-full h-full !px-[20px] md:!px-[22px] flex items-center gap-2 ${isActive ? "active" : ""}`}>
                                        {item.icon}
                                        <span className=" block">
                                            {item?.title}{" "}
                                        </span>
                                    </NavLink>
                                    :
                                    null
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </div>


            {(pathname == "/all-sports" || pathname == "/inplay" || pathname == "/upcoming" || pathname == "/live-position" || pathname == "/favorite" || pathname == "/cup-winner" || (pathname == "/login" && selectedSportTab.link != "/casino")) &&
                <div className="border-secondary-foreground pt-2">
                    <div className=" max-w-[1377px] mx-auto flex overflow-auto sm:!overflow-visible flex-wrap no-scrollbar">
                        <ul className="h-full flex whitespace-nowrap pb-2 px-1 sm:px-4 gap-[5px] sm:gap-2 justify-between sm:justify-start w-full">
                            {masterData && masterData.sports_master && masterData.sports_master.length > 0 &&
                                masterData.sports_master.map((item, key) => (
                                    <Fragment>
                                        {item.sport_id != 6 &&
                                            <li className="h-full" key={item?.sport_id + item.sports_name}>
                                                {(selectedSportId.sports_id == item.sport_id && selectedSportId.sports_name == item.sports_name) ?
                                                    <AnimatedButton>
                                                        <div className="flex flex-row items-center gap- px-2 p-1 md:p-4">
                                                            <div className="anvNotify_circ">
                                                                <img className="mx-auto" src={item.icon} alt={item?.sports_name} />
                                                            </div>
                                                            <p className="text-[12px] md:text-sm font-medium leading-[1]">
                                                                {item?.sports_name}
                                                            </p>
                                                        </div>
                                                    </AnimatedButton>
                                                    :
                                                    <button className={"h-full relative flex items-center justify-center  " +
                                                        ((selectedSportId.sports_id == item.sport_id && selectedSportId.sports_name == item.sports_name) ? "anvTab_btnActive " : "bg-active")}
                                                        onClick={() => getSport({ sports_id: item.sport_id, sports_name: item.sports_name })}>
                                                        <div className="flex flex-row items-center gap- px-2 p-1 md:p-4">
                                                            <div className="anvNotify_circ">
                                                                <img className="mx-auto" src={item.icon} alt={item?.sports_name} />
                                                            </div>
                                                            <p className="text-[12px] md:text-sm font-medium leading-[1]">
                                                                {item?.sports_name}
                                                            </p>
                                                        </div>
                                                    </button>

                                                }
                                            </li>}
                                    </Fragment>
                                ))}
                        </ul>
                    </div>
                </div>}

        </React.Fragment>
    )
}

export default SportTabs