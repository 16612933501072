// import { data } from "jquery";

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { commentaryType, showMinMax, Utils } from "App/utils";
import { setBetRequest } from "App/_redux/_actions";
import { getMarketPosition } from "App/_redux/_services"; // comment :reason for remove calculate_market_winloss
import { BsFillLockFill, BsInfoCircle } from "react-icons/bs";
import { AiFillMail, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FloatingField } from "./FloatingField";
import { BiSend } from "react-icons/bi";
import PlaceMarketBet from "./PlaceMarketBet";
import _ from "App/helper/_"


class MarketDetail extends React.Component {
  state = {
    positionData: [],
    width: window.innerWidth,
    height: window.innerHeight,
    flag: true,
    showInput: false,
    detailsId: [],
    selectedBetslip: '',
    selectedBetslipType: '',
    selectedMarketID: '',
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setBet = (bettype, price, run, market) => {
    let betData = {
      event_id: market.event_id,
      market_id: market.market_id,
      selectionId: run.selectionId,
      name: run.runnerName,
      market_name: market.market_name,
      price,
      bookmaker_type: 2,
      bettype,
    };
    this.props.dispatch(setBetRequest(betData));
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  };

  getMarketPositionData = async () => {
    let arrObj = [];
    // let v = [];
    this.props.marketList.forEach(async (market) => {
      let sendData = {};
      sendData.event_id = market.event_id;
      sendData.market_id = market.market_id;
      getMarketPosition(sendData)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.runners &&
            response.data.runners.length
          ) {
            let runnersData = JSON.parse(JSON.stringify(response.data.runners));
            runnersData.forEach((val) => (val.market_id = market.market_id));
            arrObj = [...arrObj, ...runnersData];
            this.setState({ positionData: arrObj });
          } else {
            arrObj = [];
          }
        })
        .catch((error) => { });
    });
  };

  fetchMarketPosition = (market_id, selectionId) => {
    let valData;

    if (this.state.positionData.length > 0) {
      this.state.positionData.forEach((val, index) => {
        if (val.selectionId == selectionId && val.market_id == market_id) {
          valData = val.winloss;
        }
      });
      return valData;
    }
  };

  getDisplayValue = (value, market_type, isLagai) => {
    try {
      if (value) {
        if (market_type) {
          if (market_type === 1 || market_type === 4 || market_type === 5 || market_type === 6 || market_type === 7) {
            if (market_type == 1 && isLagai && value > 1.2) {
              // value = value - 0.01;
              value = value;
            }

            let val = value * 100;
            let calValue = ((parseFloat(val) - 1 * 100) / 100).toFixed(2);
            // let calValue = ((parseFloat(val)) / 100).toFixed(2);

            return calValue;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val.toFixed(2)) - 1 * 100;
          } else {
            return value;
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  getMarketList(list) {
    for (let i = 0; i < list.length; i++) {
      let runners = list[i].runners;
      let showLagaiOnly = false;
      let lagaiBhav1 = 0;
      let lagaiBhav2 = 0;
      if (runners) {
        for (let j = 0; j < runners.length; j++) {
          let resData = this.isFavTeam(list[i], runners, j);
          runners[j].isFav = resData.isFav;
          if (!showLagaiOnly) {
            showLagaiOnly = resData.showLagaiOnly;
            lagaiBhav1 = resData.lagaiBhav1;
            lagaiBhav2 = resData.lagaiBhav2;
          }
        }
        if (showLagaiOnly) {
          list[i].showLagaiOnly = showLagaiOnly;
          list[i].lagaiBhav1 = lagaiBhav1;
          list[i].lagaiBhav2 = lagaiBhav2;
        }
        list[i].runners = runners;
      }
    }
    return list;
  }

  getValueForLagai(market, run, index, event) {
    let bhav = "--";
    // if (event?.sport_id == 1 || (event?.sport_id === 4 && market.runner_odds.length == 3)) {
    if (event?.sport_id == 1 || (event?.sport_id === 4 && market.runner_odds.length == 2)) {
      bhav = this.getDisplayValue(run?.runner_odd?.ex?.availableToBack[0]?.price, market.market_type, true);
      // if (bhav > 10 && market.market_type != 2) {
      //   return '';
      // }
      // if (
      //   bhav != "" &&
      //   event?.btn_color &&
      //   ((event?.btn_color == "Red" &&
      //     event?.commentary_button_id != 5) ||
      //     event?.btn_color == "Yellow")
      // ) {
      //   bhav = 0;
      // }
      return bhav;
    }
    if (market.showLagaiOnly && index == 0) {
      bhav = market.lagaiBhav1;
    } else if (market.showLagaiOnly && index == 1) {
      bhav = market.lagaiBhav2;
    }
    else if (market.market_type == 5 && index == 0) {
      bhav = market.lagaiBhav1;
    } else if (market.market_type == 5 && index == 1) {
      bhav = market.lagaiBhav2;
    } else if (market.market_type == 6 && index == 0) {
      bhav = market.lagaiBhav1;
    } else if (market.market_type == 6 && index == 1) {
      bhav = market.lagaiBhav2;
    } else if (market.market_type == 7) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
      // } else if (run.isFav) {
    } else if (true) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
    }

    if (
      bhav != "" &&
      event?.btn_color &&
      ((event?.btn_color == "Red" &&
        event?.commentary_button_id != 5) ||
        event?.btn_color == "Yellow")
    ) {
      bhav = 0;
      if (event?.btn_color == "Yellow") {
        // this.reloadView();
      }
    }
    return bhav;
  }

  reloadView() {
    setTimeout(() => {
      this.props.resetViewAfterYellowBtn();
    }, 3000);
  }

  getValueForKhai(market, run, event) {
    let bhav = "--";
    if (event?.sport_id === 1 || (event?.sport_id === 4 && market.runner_odds.length == 3)) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToLay[0]?.price,
        market.market_type,
        false
      );
      // if (bhav > 10 && market.market_type != 2) {
      //   return '';
      // }
      // if (
      //   bhav != "" &&
      //   event?.btn_color &&
      //   ((event?.btn_color == "Red" &&
      //     event?.commentary_button_id != 5) ||
      //     event?.btn_color == "Yellow")
      // ) {
      //   bhav = 0;
      // }
      return bhav;
    }
    // if (run.isFav && !market.showLagaiOnly) {
    if (!market.showLagaiOnly) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToLay[0]?.price,
        market.market_type,
        false
      );
    }

    if (
      bhav != "" &&
      event?.btn_color &&
      ((event?.btn_color == "Red" &&
        event?.commentary_button_id != 5) ||
        event?.btn_color == "Yellow")
    ) {
      bhav = 0;
    }
    return bhav;
  }

  showMarket(market) {
    let show = false;
    if (market) {
      for (let i = 0; i < market.runners.length; i++) {
        if (market.runners[i].lock_team_betting == 0) {
          show = true;
          break;
        }
      }
    }
    if (market.market_type == 2 || market.market_type == 1) {
      return true;
    } else {
      return show;
    }
  }

  checkValueIsExist = (value = false) => {
    return value > 0 || value < 0
  }

  getTableRow(event, market, run, index) {
    let {

      setBookmakerType,
      setBettingType,
      setPrice,
      setDispalyPrice,
      setMarketId,
      setSelectionId,
      setToggleBetForm,
      setName,
      setTeamName,
      setBatType,
      scrollToBottom,
      setSelectedData,

      openBetSlipid,
      toggleBetForm,
      betPriceDetail,
      betAmount,
      setBetAmount,
      placeBetRequest,
      onDonePressed,
      price,
      displayPrice,
      name,
      teamName,
      batType,
      selectedData,
      oddsStack,
      marketId,
      setRunnerWin,
      setWinloss,
      tempWinloss
    } = this.props;

    const lagaiValue = this.getValueForLagai(market, run, index, event);
    const khaiValue = this.getValueForKhai(market, run, event);

    let bgColorLagai = '';
    let bgColorKhai = '';
    let showRow = true;
    let cellHeight = 'auto';

    if (run.lock_team_betting) {
      bgColorLagai = '#dddddd';
      bgColorKhai = '#dddddd';
    }
    else if (event?.sport_id == 1 && lagaiValue != '' && khaiValue != '') {
      bgColorLagai = 'darkgreen';
      bgColorKhai = 'darkgreen';
    }
    else if (event?.sport_id == 1 && (lagaiValue == '' || khaiValue == '')) {
      bgColorLagai = '';
      bgColorKhai = '';
    }
    else if (market.market_type == 6 || market.market_type == 7) {
      if (index != 0) {
        bgColorLagai = '';
        bgColorKhai = '';
      }
      else if (lagaiValue != '' && lagaiValue != 0) {
        bgColorLagai = 'darkgreen';
      }
      else {
        bgColorLagai = '';
        bgColorKhai = '';
      }
    }
    else if (market.market_type == 5) {
      if (lagaiValue != '' && lagaiValue != 0) {
        bgColorLagai = 'darkgreen';
      }
      bgColorKhai = '';
    }
    else if (run.isFav) {
      bgColorLagai = 'darkgreen';
      bgColorKhai = 'darkgreen';
    }
    else if (market.showLagaiOnly) {
      bgColorLagai = 'darkgreen';
      bgColorKhai = '';
    }
    const isSoccer = (event?.sport_id == 1);
    let displayValueLagai = '';
    let displayValueKhai = '';
    if (isSoccer) {
      if (market.market_type != 1) {
        if ((run.runnerName).includes('Over')) {
          displayValueLagai = lagaiValue;
          displayValueKhai = khaiValue;
          bgColorLagai = 'darkgreen';
          bgColorKhai = 'darkgreen';
          // cellHeight = 65;
        } else if ((run.runnerName).includes('Under')) {
          bgColorLagai = '';
          bgColorKhai = '';
        }
        else {
          // showRow = false;
          bgColorLagai = '';
          bgColorKhai = '';
        }
      }
      else {
        displayValueLagai = lagaiValue;
        displayValueKhai = khaiValue;
      }
    }
    return (
      showRow &&
      <>

        <tr>
          <td className="firstTd_tab tabTd_border border-r-0">
            <div className="flex justify-between">
              <div>
                <p className="mediumPara marketHeading text-primary-foreground font-[500]">{run.runnerName}</p>
                <p className={" -mt-1 " + (run.winloss > 0 ? "xtra_smallPara_plus" : "xtra_smallPara_minus")}>{(run?.winloss ? (Number(run.winloss).toFixed(2)) : run?.winloss)}</p>
              </div>
              <div className="hidden md:block">
                <div className="text-[10px]" >

                  {(this.props.batType === "KHAI" && market.market_id == this.props.marketId && betAmount > 0) && (
                    (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                      <span className={parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"} >
                        {parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                      </span>
                      :
                      <span className={parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                        {parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                      </span>

                  )
                  }
                  {(this.props.batType === "LAGAI" && market.market_id == this.props.marketId && betAmount > 0) && (
                    (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                      <span className={parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "") - 1) : "").toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                        {parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "") - 1) : "").toFixed(2)}
                      </span>
                      :
                      <span className={parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                        {parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2)}
                      </span>
                  )
                  }
                </div>
              </div>
            </div>
          </td>

          <td className="p-0 tabTd_border border-l-0">
            <table className="w-full relative">
              <tbody>
                <tr className="relative">

                  <td className="backLagai cursor-pointer"
                    onClick={(e) => {
                      if (this.props.handleCommentaryStatusModal(commentaryType[3])) {
                        return
                      }
                      if (run.lock_team_betting) {
                        return false;
                      } else {
                        // if ((run.isFav || market.showLagaiOnly) || (!isSoccer && market.runner_odds.length == 3) || (isSoccer && displayValueLagai != '')) {
                        if ((!market.showLagaiOnly) || (!isSoccer && market.runner_odds.length == 3) || (isSoccer && displayValueLagai != '')) {
                          // if (
                          //   run?.runner_odd?.ex?.availableToBack[0]
                          //     .price &&
                          //   parseFloat(
                          //     run?.runner_odd?.ex?.availableToBack[0]
                          //       .price
                          //   ) &&
                          //   parseFloat(
                          //     run?.runner_odd?.ex?.availableToBack[0]
                          //       .price
                          //   ) > 0
                          // ) {
                          if (this.checkValueIsExist(lagaiValue) && lagaiValue > 0) {
                            this.openMarketBetSlip(market.market_id, run.selectionId, 'lay', market, run.selectionId)
                            setBookmakerType(2);
                            setBettingType(1);
                            setPrice(
                              run?.runner_odd?.ex?.availableToBack[0]
                                .price
                            );
                            setDispalyPrice(
                              isSoccer ? lagaiValue :
                                (!isSoccer && market.runner_odds.length == 3) ? lagaiValue
                                  : market.showLagaiOnly ? market.lagaiBhav1
                                    // : market.market_type == 5 && index == 0
                                    //   ? market.lagaiBhav1
                                    //   : market.market_type == 5 && index == 1
                                    //     ? market.lagaiBhav2
                                    // : run.isFav && this.getDisplayValue(run?.runner_odd?.ex?.availableToBack[0]?.price, market.market_type, true)
                                    : this.getDisplayValue(run?.runner_odd?.ex?.availableToBack[0]?.price, market.market_type, true)
                            );
                            setMarketId(market.market_id);
                            setSelectionId(run.selectionId);
                            setRunnerWin(run);
                            setName(market.market_name);
                            setTeamName(run.runnerName);
                            setSelectedData(market);
                            setBatType("LAGAI");
                            setToggleBetForm(true);
                            setBetAmount(0)
                            // scrollToBottom(run.runnerName + index + "LAGAI", e.pageY);
                          }
                          // }
                        }
                      }
                    }}
                  >
                    <div className="backBgs ">
                      <p className="mediumPara">
                        {/* {isSoccer ? displayValueLagai
                          : ((market.market_type == 5) ? lagaiValue
                            : ((market.market_type == 6 || market.market_type == 7) && lagaiValue != 's') ? lagaiValue
                              : ((market.market_type != 6 && market.market_type != 7)) ? lagaiValue
                                : 's')

                        } */}
                        {/* {run?.runner_odd?.ex?.availableToBack[0]?.price ? Utils.TwoDecimalVal((Number(run?.runner_odd?.ex?.availableToBack[0]?.price) > 1 ? (run?.runner_odd?.ex?.availableToBack[0]?.price - 1) : run?.runner_odd?.ex?.availableToBack[0]?.price)) : "0.00"} */}
                        {/* {lagaiValue} */}
                        {market.market_type == 2 ? lagaiValue :
                          Math.abs((+lagaiValue) + 1).toFixed(2)}
                      </p>
                      <p className="xtra_smallPara -mt-1">{run?.runner_odd?.ex?.availableToBack[0]?.size ? run.runner_odd.ex.availableToBack[0].size : "--"}</p>
                      {run.lock_team_betting ?
                        <div className="notAllow">
                          <BsFillLockFill size={26} color="white" />
                        </div>
                        : ''}
                    </div>
                  </td>

                  {
                    // (market.market_type !== 6 && market.market_type !== 7 && market.market_type !== 5) &&
                    <td className="layKhai cursor-pointer"
                      onClick={(e) => {
                        if (this.props.handleCommentaryStatusModal(commentaryType[3])) {
                          return
                        }
                        if (run.lock_team_betting) {
                          return false;
                        } else {
                          // console.log([run.isFav, market.showLagaiOnly, isSoccer, market.runner_odds.length, displayValueKhai])
                          // (run.isFav && !market.showLagaiOnly)
                          if ((!market.showLagaiOnly) || (!isSoccer && market.runner_odds.length == 3) || (isSoccer && displayValueKhai != '')) {
                            if (
                              khaiValue
                              // run?.runner_odd?.ex?.availableToLay[0]
                              //   .price &&
                              // parseFloat(
                              //   run?.runner_odd?.ex?.availableToLay[0]?.price
                              // ) &&
                              // parseFloat(
                              //   run?.runner_odd?.ex?.availableToLay[0]?.price
                              // ) 
                              > 0
                            ) {

                              if (this.checkValueIsExist(khaiValue) && khaiValue > 0) {
                                this.openMarketBetSlip(market.market_id, run.selectionId, 'back', market, run.selectionId)
                                setBookmakerType(2);
                                setBettingType(2);
                                setPrice(
                                  run?.runner_odd?.ex?.availableToLay[0]?.price
                                );
                                setDispalyPrice(
                                  market.market_type == 5 ?
                                    this.getDisplayValue(run?.runner_odd?.ex?.availableToLay[0]?.price, market.market_type, false)
                                    : run?.runner_odd?.ex?.availableToLay[0]?.price
                                );
                                setMarketId(market.market_id);
                                setSelectionId(run.selectionId);
                                setRunnerWin(run);
                                setName(market.market_name);
                                setTeamName(run.runnerName);
                                setSelectedData(market);
                                setBatType("KHAI");
                                setToggleBetForm(true);
                                setBetAmount(0)
                                // scrollToBottom(run.runnerName + index + "KHAI", e.pageY);
                              }
                            }
                          }
                        }
                      }}
                    >
                      <div className="layBgs">
                        <p className="mediumPara">
                          {/* {isSoccer ? displayValueKhai
                            :
                            (market.market_type == 5 ? khaiValue
                              : ((market.market_type == 6 || market.market_type == 7) && khaiValue != '') ? khaiValue
                                : ((market.market_type != 6 && market.market_type != 7)) ? khaiValue
                                  : '')
                          } */}

                          {/* {run?.runner_odd?.ex?.availableToLay[0]?.price ?
                            Utils.TwoDecimalVal((Number(run?.runner_odd?.ex?.availableToLay[0]?.price) > 1 ?
                              (run?.runner_odd?.ex?.availableToLay[0]?.price - 1)
                              : run?.runner_odd?.ex?.availableToLay[0]?.price)) : "0.00"}
                          {run?.runner_odd?.ex?.availableToLay[0]?.price} */}

                          {/* {khaiValue} */}
                          {market.market_type == 2 ? khaiValue :
                            Math.abs((+khaiValue) + 1).toFixed(2)}


                        </p>
                        <p className="xtra_smallPara -mt-1">{run?.runner_odd?.ex?.availableToLay[0]?.size ? run.runner_odd.ex.availableToLay[0].size : "--"}</p>
                        {run.lock_team_betting ?

                          <div className="notAllow">
                            <BsFillLockFill size={26} color="white" />
                          </div>
                          : ""}
                      </div>
                    </td>
                  }
                  {!_.isNull(market.match_status) && !_.isEmpty(market.match_status) && !Number(market.match_status) && market.match_status !== 'OPEN' && market.match_status !== 'ACTIVE' && (Number(market.match_status) != 0) &&
                    <div className="notAllow ">{market.match_status}</div>
                  }

                </tr>

              </tbody>
            </table>
          </td>
        </tr>
        {toggleBetForm && this.state.selectedBetslip == run.selectionId && this.state.selectedMarketID == market.market_id && market.market_id == openBetSlipid &&
          <tr>
            <td colSpan='3'>
              <PlaceMarketBet
                betPriceDetail={betPriceDetail}
                betAmount={betAmount}
                setBetAmount={setBetAmount}
                placeBetRequest={placeBetRequest}
                setToggleBetForm={setToggleBetForm}
                onDonePressed={onDonePressed}
                price={price}
                displayPrice={displayPrice}
                name={name}
                teamName={teamName}
                batType={batType}
                event={event}
                selectedData={selectedData}
                oddsStack={oddsStack}
                openMarketBetSlip={() => this.openMarketBetSlip(market.market_id, run.selectionId, 'back', market, run.selectionId)}
                setWinloss={setWinloss}
                tempWinloss={tempWinloss}
                marketId={marketId}
              />
            </td>
          </tr>
        }
      </>
    )
  }


  toggleDetailsMenu = (id) => {
    const detailsArray = this.state?.detailsId;
    if (detailsArray.indexOf(id) >= 0) {
      detailsArray.splice(detailsArray.indexOf(id), 1);
    } else {
      detailsArray.push(id);
    }
    this.setState({ detailsArray: detailsArray })
  };

  openMarketBetSlip = (market_id, selectionId, type, selectedBetItem, runnerSelectionId) => {
    const { openBetSlipMarketId, batType } = this.props;
    openBetSlipMarketId(market_id);
    this.setState({
      selectedMarketID: market_id,
      selectedBetItem: selectedBetItem,
      runnerSelectionId: runnerSelectionId,
      // selectedBetslip: (market_id == this.state.selectedMarketID && selectionId == this.state.selectedBetslip && this.state.selectedBetslipType == type ? '' : selectionId),
      // selectedBetslip: (market_id == this.state.selectedMarketID && selectionId == this.state.selectedBetslip ? '' : selectionId),
      selectedBetslip: (market_id == this.state.selectedMarketID && selectionId == this.state.selectedBetslip ? selectionId : selectionId),
      selectedBetslipType: type,
    })
  }

  render() {
    let { event, marketList } = this.props;
    marketList = marketList ? this.getMarketList(marketList) : [];
    return (
      <>
        <div className="container-fluid">
          <div className="row scroll-form" >
            {marketList.map((market, mIndex) => (
              this.showMarket(market) &&
              <div className="overflow-auto rounded-[4px] mt-2  px-0" key={mIndex}>
                <table className="w-full anvWin_table" >
                  <thead>
                    <tr className="customGradient2">
                      <th className="trLeft">
                        <div>
                          <p className="mediumPara marketHeading text-active-foreground">
                            {/* {market?.market_type == 7
                              ? "Lottery 9.50"
                              : "Market"}{" "}
                            : {market.market_name} */}
                            {market.market_name}
                          </p>
                          <p className="xtra_smallPara text-active-foreground">
                            {!this.state.detailsId.includes(mIndex) &&
                              <>
                                {showMinMax("market", market, event, true)}
                              </>
                            }
                          </p>
                        </div>
                      </th>

                      <th className="trRight">
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th className="w-[50%] pl-[12px]">
                                <div className="text-center leading-[1.3]">
                                  <p className="layBackHeading text-active-foreground">BACK</p>
                                  {/* <p className="xtra_smallPara">LAGAI</p> */}
                                </div>
                              </th>

                              <th className="w-[50%] pl-[35px]">
                                <div className="text-center">
                                  <p className="layBackHeading leading-[1.3] text-active-foreground">LAY</p>
                                  {/* <p className="xtra_smallPara">KHAI</p> */}
                                </div>
                              </th>
                              <th>
                                <div className="pr-2 cursor-pointer" onClick={() => this.setState({ [market.market_name]: this.state[market.market_name] == false ? true : false })}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none" className={"scale-[0.8] " + (this.state[market.market_name] == false ? "rotate-180" : "")}>
                                    <path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" className=" stroke-[#000] dark:stroke-[#fff]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </th>
                    </tr>
                  </thead>
                  {(this.state[market.market_name] == undefined || this.state[market.market_name]) && !this.state.detailsId.includes(mIndex) &&
                    <tbody>

                      {market.runners.map((run, index) => (
                        <React.Fragment key={index}>
                          {(event?.sport_id == 1 && market.market_type != 1 && (run.runnerName).includes('Over') && market.match_status != 'OPEN') || (event?.sport_id == 1 && market.match_status != 'OPEN' && market.market_type == 1) ? (

                            this.getTableRow(event, market, run, index)
                          ) : (

                            this.getTableRow(event, market, run, index)
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  }
                </table>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  isFavTeam(market, runners, index) {
    const minBhavLimit = 0.96;
    const maxBhavLimit = 1.0;
    let showLagaiOnly = false;
    let lagaiBhav1 = 0;
    let lagaiBhav2 = 0;
    let lagai1 = this.getDisplayValue(
      runners[0]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let lagai2 = this.getDisplayValue(
      runners[1]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let isFav = false;
    let resultData = {};
    const isSoccer = (this.props.event?.sport_id == 1);
    if (isSoccer) {
      if (market.market_type != 1) {
        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        }
        if (market.market_type == 5 || market.market_type == 6 || market.market_type == 7) {
          showLagaiOnly = false; //previous value true
          lagaiBhav1 = lagai1;
          lagaiBhav2 = lagai2;
        }
        resultData = {
          isFav: isFav,
          showLagaiOnly: showLagaiOnly,
          lagaiBhav1: lagaiBhav1,
          lagaiBhav2: lagaiBhav2,
        };
      }
    }
    else {
      if (runners.length == 3) {
        //TEST MATCH

        let lagai3 = this.getDisplayValue(
          runners[2]?.runner_odd?.ex?.availableToBack[0]?.price,
          market.market_type,
          true
        );
        let khai3 = this.getDisplayValue(
          runners[2]?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            // showLagaiOnly = true;
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            //  showLagaiOnly = true;
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 2) {
          if (khai3 > 9.99 && lagai3 > 9.99) {
            isFav = false;
          } else {
            isFav = true;
          }
        }
      } else if (runners.length == 2) {
        //Other MATCH

        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        }
        // show only lagai value if runner value is 2 
        if (market.market_type == 2 && lagai1 >= 94) {
          showLagaiOnly = true;
          isFav = false;
          lagaiBhav1 = lagai1;
          lagaiBhav2 = lagai2;
        }
      }
      if (market.market_type == 2) {
        // if (market.runner_odds[index]?.status == 'ACTIVE') {
        const back = runners[index]?.runner_odd?.ex?.availableToBack[0]?.price
        const lay = runners[index]?.runner_odd?.ex?.availableToLay[0]?.price
        const selectedRunner = market.runner_odds.find(item => runners[index]?.selectionId == item?.selectionId)
        if (selectedRunner?.status == 'ACTIVE' && (lay || back)) {
          if (lagai1 < 100 && lagai1 > 95) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai1;
            showLagaiOnly = true;
            isFav = false;
          }
          else if (lagai2 < 100 && lagai2 > 95) {
            showLagaiOnly = true;
            lagaiBhav1 = lagai2;
            lagaiBhav2 = lagai2;
            isFav = false;
          }
          else {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
            isFav = true;
            showLagaiOnly = false;
          }
        }
        else {
          isFav = false;
        }

      }
      // hide value if lagai value is gt 100
      // if (market.market_type == 2 && lagai1 >= 100 ) {
      //   showLagaiOnly = false;
      //   isFav = false;
      // }

      if (market.market_type == 5 || market.market_type == 6 || market.market_type == 7) {
        // showLagaiOnly = false; //previous value true
        showLagaiOnly = true; //previous value true
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
      }

      if (+this?.props?.event?.cup_event === 1) {
        isFav = true;
      }

      resultData = {
        isFav: isFav,
        showLagaiOnly: showLagaiOnly,
        lagaiBhav1: lagaiBhav1,
        lagaiBhav2: lagaiBhav2,
      };
    }
    return resultData;
  }
}

export default React.memo(MarketDetail)
