import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AboutUs = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">About Us</h1>

            <section className="mb-8">
                <p className="mb-4">
                    Over 100,000 users bet with Future9Club.com.
                </p>

                <h2 className="text-xl font-semibold mb-4">Types of Bets</h2>
                <p className="mb-4">
                    Future9Club offers many additional markets on specific events. We also have a great selection of markets on international games on a club and country level.
                </p>
                <p className="mb-4">
                    Future9Club offers types of bets such as singles, accumulators, system, and chain bets.
                </p>
                <p className="mb-4">
                    Every day Future9Club presents "The Matches of the Day," which comprise the best daily offers. We offer you enhanced odds and increased stake amounts on all those matches!
                </p>

                <h2 className="text-xl font-semibold mb-4">Live Bets</h2>
                <p className="mb-4">
                    Live bets are available 24 hours a day. For most sports, more than 30 markets are offered for each event, including bets on corners, yellow cards, free kicks, etc.
                </p>

                <h2 className="text-xl font-semibold mb-4">Deposit/Withdrawal</h2>
                <p className="mb-4">
                    You can top up your account at Future9Club betting shops. Winnings are paid out through the same method that was used to make a deposit.
                </p>

                <h2 className="text-xl font-semibold mb-4">Customer Support</h2>
                <p className="mb-4">
                    Future9Club provides 24/7 customer support via phone. Future9Club also offers an Online Consultant service on its website. Alternatively, you can always get in touch with Future9Club via email. All of our contact details can be found in the Contacts section.
                </p>

                <h2 className="text-xl font-semibold mb-4">Exchange</h2>
                <p>
                    Future9Club EXCHANGE includes over 1,000 events daily. You can bet on a variety of popular sports: football, tennis, basketball, volleyball, ice hockey, golf, boxing, handball, American football, hockey, baseball, table tennis, biathlon, Aussie rules, and bandy. We also offer bets on cricket, snooker, Formula 1, cycling, ski jumping, curling, floorball, inline hockey, and water polo. With Future9Club, you can bet on more than 1,000 sporting events every day.
                </p>
            </section>
        </div>
    );
};

export default AboutUs;
